* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  // DO NOT ADD overflow-x: hidden here. It will break the position sticky option in other components
}
body header,
body main {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Make Wallet Connect modal appear about MUI modals */
body {
  --wcm-z-index: 1500;
}

@font-face {
  font-family: 'Posterama';
  src: url('/fonts/Posterama-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'K2D';
  src: url('/fonts/K2D-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  line-height: 1;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg) scale(0.95);
    scale: 1;
    rotate: 0.95;
  }

  25% {
    transform: rotate(-15deg) scale(1);
    scale: 1.2;
  }

  50% {
    transform: rotate(0deg) scale(0.95);
    scale: 1;
  }

  75% {
    transform: rotate(15deg) scale(1);
    scale: 1.2;
  }
}

// Transition from right to left on page load
@keyframes move-out {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-400px);
  }
}

@keyframes move-in {
  from {
    transform: translateX(400px);
  }

  to {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 600px) {
  body main {
    view-transition-name: container-move;
  }

  ::view-transition-old(container-move) {
    animation: 0.2s ease-out both move-out;
  }

  ::view-transition-new(container-move) {
    animation: 0.2s ease-in both move-in;
  }
}

/** Sonner */
body [data-sonner-toaster][data-theme=dark] {
  --normal-bg: #000;
  --normal-border: hsl(0, 0%, 20%);
  --normal-text: var(--gray1);
  --success-bg: var(--mui-palette-Alert-successFilledBg); // hsl(150, 100%, 6%);
  --success-border: var(--mui-palette-Alert-successFilledBg); // hsl(147, 100%, 12%);
  --success-text: var(--mui-palette-Alert-successColor); // hsl(150, 86%, 65%);
  --info-bg: var(--mui-palette-Alert-infoFilledBg); // hsl(215, 100%, 6%);
  --info-border: var(--mui-palette-Alert-infoFilledBg); // hsl(223, 100%, 12%);
  --info-text: var(--mui-palette-Alert-infoColor); // hsl(216, 87%, 65%);
  --warning-bg: var(--mui-palette-Alert-warningFilledBg); // hsl(64, 100%, 6%);
  --warning-border: var(--mui-palette-Alert-warningFilledBg); // hsl(60, 100%, 12%);
  --warning-text: var(--mui-palette-Alert-warningColor); // hsl(46, 87%, 65%);
  --error-bg: var(--mui-palette-Alert-errorFilledBg); // hsl(358, 76%, 10%);
  --error-border: var(--mui-palette-Alert-errorFilledBg); // hsl(357, 89%, 16%);
  --error-text: var(--mui-palette-Alert-errorColor); // hsl(358, 100%, 81%)
}
.sonner-toast {
  &[data-type='success'] {
    background-color: var(--mui-palette-success-dark);
    border-color: var(--mui-palette-success-dark);
  }
}

// Special styles
.MuiTableContainer-root.contained-table {
  
  overflow: hidden;

  .MuiTableCell-root {
    background-color: var(--mui-palette-background-paper);
    border-bottom: 4px solid var(--mui-palette-background-default);
    // &.MuiTableCell-sizeSmall {
    //   border-bottom-width: 2px;
    // }
  }
  // we have to apply rounded corners to the TD instead of TR tags
  .MuiTableCell-root:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .MuiTableCell-root:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}